import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const SORT = {
  PRICE_ASC: 1,
  PRICE_DESC: 2,
  RECENT: 3,
  ALFA: 4
};

let SORT_OPTIONS = [
  { value: SORT.ALFA, label: 'Alfabetico' },
  { value: SORT.RECENT, label: 'Más reciente' },
];

const SidebarOrder = ({ show, selected, onSelect, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => onClose()}>
      <div className="absolute inset-0 overflow-hidden">
        <Dialog.Overlay className="absolute inset-0" />

        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pointer-events-auto w-screen max-w-md">
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900"> Ordenar por </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => onClose()}
                        >
                          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2 mx-2">
                      {SORT_OPTIONS.map(x => (
                        <li
                          key={`modal-sort-${x.value}`}
                          onClick={() => {
                            onSelect(x.value);
                            onClose();
                          }}
                        >
                          <div className={
                            'flex items-center cursor-pointer p-2 text-base font-normal text-gray-500 rounded-lg hover:bg-gray-100' +
                            (selected === x.value ? ' bg-gray-200' : '')
                          }>
                            <span className="ml-3">{x.label}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                    onClick={() => onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

export default SidebarOrder;