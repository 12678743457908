/* eslint-disable eqeqeq */
import React from 'react';
import { formatAmount, getFinalImageColor, toAmount } from '../utils/parse';
import ShoppingCartIcon from '../assets/icons/shopping-cart.png';


const SELL_TYPES = {
  NORMAL: 1,
  SIZE_COLORS: 2
}

const CartResume = ({ cart, onClick, show_prices }) => {
  let total = 0;
  for (const product of cart) {
    let sale_price = 0;
    if(Number(product.type) === SELL_TYPES.NORMAL){
      if(product.default_price == 1){
        sale_price = product.sale_price
      }
      else{
        // Busca el primer objeto en product.prices donde default_price es 1
        const priceObj = product.prices.find(price => price.default_price === 1);
        // Si se encuentra un objeto que cumple con la condición, asigna su price a sale_price
        if (priceObj) {
          sale_price = priceObj.price;
        }
      }
    }
    else if (product.type == SELL_TYPES.SIZE_COLORS) {
      const size_color = product?.size_colors?.find((i) => {
        if (product.size_id && product.color_id) {
          return i.size_id == product.size_id && i.color_id == product.color_id;
        }
        else if (product.size_id) {
          return i.size_id == product.size_id && i.color_id == null;
        }
        else if (product.color_id) {
          return i.color_id == product.color_id && i.size_id == null;
        }
      });

      const priceObj = size_color?.prices.find(price => price.default_price === 1);
      // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
      if (priceObj) {
        sale_price = priceObj.price;
      }
      else {
        sale_price = size_color?.sale_price
      }
    }
    total = toAmount(total + sale_price * product.quantity);
  }

  return (
    <div onClick={() => onClick()} className="cart-resume">
      <div className="flex flex-col justify-between items-center">
        {!!cart.length && (
          <span className="inline-flex justify-center items-center w-5 h-5 absolute bottom-12 ml-2 z-[1] text-xs font-medium bg-yellow-400 rounded-full">
            { cart.length }
          </span>
        )}
        <img
          style={{ ...getFinalImageColor('--bg-header','#000000') }}
          src={ShoppingCartIcon}
          alt="Carrito de compras"
          title="Carrito de compras"
        />
      </div>
      {(show_prices && !!cart.length) && (
        <span className="text-xs md:text-sm text-center text-[--fg-header] absolute top-[4rem] md:top-[4.5rem]">
          { formatAmount(total) }
        </span>
      )}
    </div>
  );
}

export default CartResume;