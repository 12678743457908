/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import Product from './ProductCard';

const ProductCarousel = ({ identifier = '', products, cart, addToCart, showPrices, setShowProduct, interval }) => {
  const componentID = `carousel-${identifier}`;
  const cContRef = useRef();
  const hScrollRef = useRef();
  const bScrollLeftRef = useRef();
  const bScrollRightRef = useRef();

  const showCarouselButtons = () => {
    if (cContRef.current.offsetWidth > hScrollRef.current.offsetWidth)
      bScrollRightRef.current.classList.add('sm:visible');
  }

  const scrollHorizontally = (val) => {
    let scrollAmount = hScrollRef.current.offsetWidth - ( !!interval ? 0 : 50 );
    let maxScroll = -cContRef.current.offsetWidth + hScrollRef.current.offsetWidth + 8;
    const positionInPixels = String(cContRef.current.style?.marginLeft).match(/-?\d+/g);
    let currentScrollPosition = (!!positionInPixels ? +positionInPixels[0] : 0) + (val * scrollAmount);

    if (currentScrollPosition > 0) {
      currentScrollPosition = 0;
      bScrollLeftRef.current.classList.remove('sm:visible');
    } else {
      bScrollLeftRef.current.classList.add('sm:visible');
    }

    if (currentScrollPosition <= maxScroll) {
      currentScrollPosition = maxScroll;
      bScrollRightRef.current.classList.remove('sm:visible');
    } else {
      bScrollRightRef.current.classList.add('sm:visible');
    }

    cContRef.current.style.marginLeft = currentScrollPosition + 'px';
  }

  useEffect(() => {
    if (!!products.length) showCarouselButtons();
    setTimeout(() => {
      if (!interval) return;
      const parent = document.getElementById(componentID);
      const elements = parent.getElementsByClassName('product-wrapper');
      const parentWidth = parent?.offsetWidth;
      for (let i = 0; i < elements.length; i++) {
        const itemWidth = (!!interval && !!parentWidth) ? `${parentWidth / interval}px` : '100%';
        elements.item(i).style.width = itemWidth;
      }
    }, 0);
  }, [products]);

  if (!products.length) return <></>;

  return (
    <section id={componentID}>
      <h1 className="mx-2 font-medium mb-2">Podría interesarte</h1>
      <div className="flex items-center whitespace-nowrap overflow-x-scroll hide-scroll-bar mb-8" ref={hScrollRef}>
        <button
          onClick={() => scrollHorizontally(1)}
          className="carousel-button left-0 invisible"
          type="button"
          ref={bScrollLeftRef}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>

        <button
          onClick={() => scrollHorizontally(-1)}
          className="carousel-button right-0 invisible"
          type="button"
          ref={bScrollRightRef}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>

        <div className="show-grid horizontal-products" ref={cContRef}>
          {products.map((product,index) => {
            const isSelected = Boolean(cart.find(x => x.id === product.id));

            return (
              <div className="product-wrapper" key={`product-${product.id}`}>
                <Product
                  product={product}
                  isSelected={isSelected}
                  addToCart={addToCart}
                  show_prices={showPrices}
                  showProduct={() => setShowProduct({ show: true, product: product, isSelected })}
                  showCategory={false}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ProductCarousel;