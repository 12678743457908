/* eslint-disable eqeqeq */
import React from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { copy, formatAmount } from '../utils/parse';
import ProductCarousel from './components/ProductCarousel';

import DefaultPhoto from '../assets/images/photo.png';

const SELL_TYPES = {
  NORMAL: 1,
  SIZE_COLORS: 2
}

const ProductDetails = ({ showProduct, setShowProduct, showPrices, addToCart, setShowPreview, interestingProducts }) => {
  const cart = useSelector(state => state.cart);
  const sizes = useSelector(state => state.sizes);
  const productCategories = useSelector(state => state.productCategories);
  const colors = useSelector(state => state.colors);

  const product = copy(showProduct.product);
  console.log('Product: ', product)
  
  // Variables para validación de precios con unidad
  const pricesWithUnit = product.prices?.filter(price => price.unit_description !== null) || [];
  const hasUnitPrices = pricesWithUnit.length > 0;

  const prodSizes = [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))];
  const prodColors = [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))];
  const isSelected = product.type == SELL_TYPES.SIZE_COLORS
    ? Boolean(cart.find(x => x.id === product.id && x.color_id == product.color_id && x.size_id == product.size_id))
    : Boolean(cart.find(x => x.id === product.id));

  const enoughStock = product.type === SELL_TYPES.SIZE_COLORS
    ? product.size_colors.some(x => x.stock > 0)
    : product.stock > 0;

  const size_color = product?.size_colors?.find((i) => {
    if (product.size_id && product.color_id) {
      return i.size_id == product.size_id && i.color_id == product.color_id;
    }
    else if (product.size_id) {
      return i.size_id == product.size_id && i.color_id == null;
    }
    else if (product.color_id) {
      return i.color_id == product.color_id && i.size_id == null;
    }
  });

  const showPreview = () => {
    setShowPreview({ show: true, product: product });
  }

  const handleSelectSize = (e) => {
    e.stopPropagation();
    product.size_id = Number(e.target.value);
    setShowProduct(s => ({ ...s, product: { ...s.product, size_id: Number(e.target.value) } }));
  }

  const handleSelectColor = (e, value) => {
    e.stopPropagation();
    product.color_id = Number(value);
    setShowProduct(s => ({ ...s, product: { ...s.product, color_id: Number(value) } }));
  }

  const handlePriceSelect = (value) => {
    product.selected_price_id = Number(value);
    setShowProduct(s => ({ ...s, product: { ...s.product, selected_price_id: Number(value) } }));
  }

  const minPrice = (product) => {
    if (Number(product.type) === SELL_TYPES.NORMAL) {
      if (product.default_price == 1) {
        return product.sale_price;
      }
      else {
        const priceObj = product.prices.find(price => price.default_price === 1);
        if (priceObj) {
          return priceObj.price;
        }
        else {
          return product.sale_price;
        }
      }
    }

    let prices = product.size_colors.filter(i => i.stock > 0).map(i => {
      const priceObj = i.prices.find(price => price.default_price === 1);
      if (priceObj) {
        return priceObj.price;
      }
      else {
        return i.sale_price;
      }
    });
    if (prices.length == 0) {
      prices = [0];
    }
    return Math.min(...prices);
  }

  const handleAddToCart = (e) => {
    e.stopPropagation();
    console.log('selectedPriceId: ', product.selected_price_id);
    console.log('product.prices: ', product.prices);

    const selectedPrice = product.prices?.find(p => p.id == product.selected_price_id);
    let updatedProduct = null;

    console.log('selectedPrice: ', selectedPrice);

    if (selectedPrice) {
      const updatedPrices = product.prices.map(p =>
        p.id == product.selected_price_id ? { ...p, default_price: 1 } : p
      );

      updatedProduct = {
        ...product,
        prices: updatedPrices, // Se actualiza la referencia de prices
        default_price: 2, // Modificar default_price en el producto
        selected_price: selectedPrice.price // Precio seleccionado
      };
    }

    if (hasUnitPrices && !product.selected_price_id) {
      toast.error('Por favor seleccione un precio');
      return;
    }

    if (updatedProduct !== null) {
      console.log('updatedProduct: ', updatedProduct);
      addToCart(updatedProduct);
      return;
    }

    addToCart(product);
  };

  // const Price = () => {
  //   if (showPrices && (product.type == SELL_TYPES.NORMAL || size_color != null)) return (
  //     <h3 className="product-price text-2xl font-bold leading-none">
  //       {formatAmount(product.type == SELL_TYPES.NORMAL ? product.sale_price : size_color?.sale_price)}
  //     </h3>
  //   )

  //   return <></>;
  // }

  const Price = () => {
    if (!showPrices) return <></>;

    const pricesWithUnit = product.prices?.filter(price => price.unit_description !== null) || [];
    const hasUnitPrices = pricesWithUnit.length > 0;
  
    if (hasUnitPrices) {
      return (
        <select
          className="product-price font-medium leading-none"
          value={product.selected_price_id || ''}
          onChange={(e) => handlePriceSelect(e.target.value)}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <option value="" disabled hidden>
            Seleccione un precio
          </option>
          {pricesWithUnit.map((price) => (
            <option
              key={price.id}
              value={price.id}
              //disabled={!price.stock}
            >
              {formatAmount(price.price)} - {price.unit_description}
            </option>
          ))}
        </select>
      );
    }

    // Lógica para precios sin unidad
    let precio = 0
    if(Number(product.type) === SELL_TYPES.NORMAL){
      if(product.default_price == 1){
        precio = product.sale_price
      }
      else{
        // Busca el primer objeto en product.prices donde default_price es 1
        const priceObj = product.prices.find(price => price.default_price === 1);
        // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
        if (priceObj) {
          precio = priceObj.price;
        }
      }
    }
    else if (size_color?.sale_price) {
      // Busca el primer objeto en product.prices donde default_price es 1
      const priceObj = size_color?.prices.find(price => price.default_price === 1);
      // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
      if (priceObj) {
        precio = priceObj.price;
      }
      else {
        precio = size_color?.sale_price
      }
    }
    else {
      precio = minPrice(product);
    }

    return (
      <h3 className="product-price text-2xl font-bold leading-none">
        {/* {formatAmount(product.type == SELL_TYPES.NORMAL ? product.sale_price : size_color?.sale_price)} */}
        {formatAmount(precio)}
      </h3>
    )
  }

  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-black bg-opacity-25" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="product-detail-modal w-full max-w-xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
            <div className="text-lg font-medium leading-6 px-5 py-4 text-gray-900 flex flex-row items-center justify-end">
              <div className="cursor-pointer" onClick={() => setShowProduct(s => ({ ...s, show: false }))}>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </div>
            </div>

            <div className="px-2 pb-6">
              <div className="product-details">
                <div className="product-image w-60 h-60">
                  <img
                    className="object-cover text-xs opacity-100"
                    src={process.env.REACT_APP_STORAGE + product.photo?.file}
                    alt={product.name}
                    onClick={showPreview}
                    onError={el => el.target.src = DefaultPhoto}
                  />
                </div>
                <div className="product-info">
                  <div>
                    <p className="text-lg lg:text-xl leading-5 lg:leading-6 pb-2">{ product?.name }</p>
                    <div className="flex flex-col gap-1">
                      <h3 className="product-category" title="Categoría">
                        { productCategories.find(x => x.id === product.category_id)?.name }
                      </h3>
                      <Price />
                    </div>

                    {
                      product.type == SELL_TYPES.SIZE_COLORS && (
                        <div className="product-size-color mt-4">
                          {
                            prodColors.length > 0 && (
                              <div style={{ display: 'flex' }}>
                                {
                                  prodColors.map((i) => {
                                    return (
                                      <div
                                        key={`color-${product.id}${i}${new Date().getMilliseconds()}`}
                                        onClick={ (e) => handleSelectColor(e, i) }
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          borderRadius: '10px',
                                          marginRight: '10px',
                                          marginBottom: '10px',
                                          cursor: 'pointer',
                                          backgroundColor: colors?.find(item => item.id == i)?.hex,
                                          borderWidth: 5,
                                          borderColor: product.color_id == i ? '#00000066' : 'transparent',
                                          boxShadow: '0px 1px 5px -2px #000000',
                                        }}></div>
                                    )
                                  })
                                }
                              </div>
                            )
                          }

                          {
                            prodSizes.length > 0 && (
                              <div className="flex flex-row justify-start gap-4">
                                <label className="font-medium">Talla:</label>
                                <select
                                  value={ product.size_id || '' }
                                  onChange={ handleSelectSize }
                                  onClick={ (e) => e.stopPropagation() }
                                >
                                  <option value="">Seleccione</option>
                                  {
                                    prodSizes.map((_item) => {
                                      const size = sizes.find(i => i.id == _item);

                                      return (
                                        <option
                                          key={`size-${product.id}${_item}${new Date().getMilliseconds()}`}
                                          value={ size?.id }
                                        >
                                          { size?.name }
                                        </option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </div>

                  <div>
                    {enoughStock ? (
                      <button className="add-product" onClick={handleAddToCart}>
                        { isSelected ? 'Añadido' : 'Añadir' }
                      </button>
                    ) : (
                      <div className="product-action inline-flex items-center">
                        <span className="text-sm text-red-600">Sin stock</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <p className="pt-4">{ product.description_store }</p>
            </div>

            <ProductCarousel
              products={interestingProducts}
              cart={cart}
              addToCart={addToCart}
              showPrices={showPrices}
              setShowPreview={setShowPreview}
              setShowProduct={setShowProduct}
              identifier="from-product-details"
              interval={2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetails;