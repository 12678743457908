/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { formatAmount } from '../../utils/parse';
import { ButtonAdd, ButtonAdded } from './Buttons';

import DefaultPhoto from '../../assets/images/photo.png';

const SELL_TYPES = {
  NORMAL: 1,
  SIZE_COLORS: 2
}

const Product = ({ product, isSelected, addToCart, show_prices, showPreview, showProduct, showCategory = true }) => {
  const colors = useSelector(state => state.colors);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  
  // Variables para validación de precios con unidad
  const pricesWithUnit = product.prices?.filter(price => price.unit_description !== null) || [];
  const hasUnitPrices = pricesWithUnit.length > 0;
  // console.log('hasUnitPrices: ', hasUnitPrices)

  const prodColors = product.type === SELL_TYPES.SIZE_COLORS
    ? [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))] || []
    : [];

  const enoughStock = product.type === SELL_TYPES.SIZE_COLORS
    ? product.size_colors.some(x => x.stock > 0)
    : product.stock > 0;

  useEffect(() => {
    setSelectedPriceId(null);
  }, [product]);

  const handleAddToCart = (e) => {
    // TODO: En caso de realizar algún cambio en esta función, replicar en ProductDetails->handleAddToCart
    e.stopPropagation();
    console.log('selectedPriceId: ', selectedPriceId);
    console.log('product.prices: ', product.prices);

    const selectedPrice = product.prices?.find(p => p.id == selectedPriceId);
    let updatedProduct = null;

    console.log('selectedPrice: ', selectedPrice);

    if (selectedPrice) {
      const updatedPrices = product.prices.map(p =>
        p.id == selectedPriceId ? { ...p, default_price: 1 } : p
      );

      updatedProduct = {
        ...product,
        prices: updatedPrices, // Se actualiza la referencia de prices
        default_price: 2, // Modificar default_price en el producto
        selected_price: selectedPrice.price // Precio seleccionado
      };
    }

    if (hasUnitPrices && !selectedPriceId) {
      toast.error('Por favor seleccione un precio');
      return;
    }

    if (updatedProduct !== null) {
      console.log('updatedProduct: ', updatedProduct);
      addToCart(updatedProduct);
      return;
    }

    addToCart(product);
  };


  return (
    <div
      className="product-item"
      onClick={() => showProduct?.()}
    >
      <div className="flex sm:items-center">
        <div className="product-image">
          <img
            className={`object-cover text-xs ${enoughStock ? 'opacity-100' : 'opacity-25'}`}
            src={`${process.env.REACT_APP_STORAGE}${product.photo?.file}`}
            alt={product.name}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   if (!showProduct && showPreview) showPreview();
            // }}
            onError={el => el.target.src = DefaultPhoto}
          />
        </div>
      </div>

      <div className="product-bottom">
        <div className="flex flex-1 flex-col gap-2">
          {enoughStock ? (
            <div>
              {product.type === SELL_TYPES.NORMAL ? (
                isSelected ? (
                  <ButtonAdded onClick={handleAddToCart} />
                ) : (
                  <ButtonAdd onClick={handleAddToCart} />
                )
              ) : (
                <ButtonAdd multiple />
              )}
            </div>
          ) : (
            <div className="product-action inline-flex items-center">
              <span className="text-sm text-red-600">Sin stock</span>
            </div>
          )}

          <div
            className="flex flex-row flex-wrap justify-between"
            onClick={(e) => e.target === e.currentTarget && showProduct?.()}
          >
            <Price
              product={product}
              show_prices={show_prices}
              selectedPriceId={selectedPriceId}
              onPriceSelect={setSelectedPriceId}
            />

            {prodColors.length > 0 && (
              <div className="color-preview">
                {prodColors.map((i) => {
                  const hexColor = colors.find(item => item.id === i)?.hex || '#FFFFFF';
                  return (
                    <div
                      key={`color-${product.id}-${i}`}
                      className="color-circle"
                      style={{ backgroundColor: hexColor, borderWidth: 0 }}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="product-name">
            <span onClick={(e) => {
              e.stopPropagation();
              showProduct?.();
            }}>
              {product.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Price = ({ product, show_prices, selectedPriceId, onPriceSelect }) => {
  const pricesWithUnit = product.prices?.filter(price => price.unit_description !== null) || [];
  const hasUnitPrices = pricesWithUnit.length > 0;

  if (!show_prices) return null;

  if (hasUnitPrices) {
    return (
      <select
        className="product-price font-medium leading-none"
        value={selectedPriceId || ''}
        onChange={(e) => onPriceSelect(e.target.value)}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <option value="" disabled hidden>
          Seleccione un precio
        </option>
        {pricesWithUnit.map((price) => (
          <option
            key={price.id}
            value={price.id}
            //disabled={!price.stock}
          >
            {formatAmount(price.price)} - {price.unit_description}
          </option>
        ))}
      </select>
    );
  }

  // Lógica para precios sin unidad
  let precio = 0;
  if (Number(product.type) === SELL_TYPES.NORMAL) {
    const priceObj = product.default_price == 1
      ? { price: product.sale_price }
      : product.prices.find(price => price.default_price === 1);
    precio = priceObj?.price || 0;
  } else {
    precio = product.minPrice;
  }

  return <h3 className="product-price font-medium leading-none">{formatAmount(precio)}</h3>;
};

// const Product = ({ product, isSelected, addToCart, show_prices, showPreview, showProduct, showCategory = true }) => {
//   const colors = useSelector(state => state.colors);
//   const prodColors = product.type == SELL_TYPES.SIZE_COLORS
//     ? [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))] || []
//     : [];
//   const enoughStock = product.type === SELL_TYPES.SIZE_COLORS
//     ? product.size_colors.some(x => x.stock > 0)
//     : product.stock > 0;

//   const handleAddToCart = (e) => {
//     e.stopPropagation();
//     addToCart();
//   }

//   const Price = () => {
//     if (!show_prices) return <></>;

//     let precio = 0
//     if(Number(product.type) === SELL_TYPES.NORMAL){
//       if(product.default_price == 1){
//         precio = product.sale_price
//       }
//       else{
//         // Busca el primer objeto en product.prices donde default_price es 1
//         const priceObj = product.prices.find(price => price.default_price === 1);
//         // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
//         if (priceObj) {
//           precio = priceObj.price;
//         }
//       }
//     }
//     else{
//       precio = product.minPrice
//     }

//     return (
//       <h3 className="product-price font-medium leading-none">
//         {/* {Number(product.type) === SELL_TYPES.NORMAL
//           ? formatAmount(product.sale_price)
//           : formatAmount(product.minPrice)
//         } */}
//         { formatAmount(precio) }
//       </h3>
//     )
//   }

//   return (
//     <div
//       className="product-item"
//       onClick={() => !!showProduct ? showProduct() : {}}
//     >
//       <div className="flex sm:items-center">
//         <div className="product-image">
//           <img
//             className={'object-cover text-xs ' + (enoughStock ? 'opacity-100' : 'opacity-25')}
//             src={process.env.REACT_APP_STORAGE + product.photo?.file}
//             alt={product.name}
//             onClick={() => !showProduct && !!showPreview ? showPreview() : {}}
//           />
//         </div>
//       </div>

//       <div className="product-bottom">
//         <div className="flex flex-1 flex-col gap-2">
//           {enoughStock ? (
//             <div>
//               {product.type == SELL_TYPES.NORMAL ? (
//                 isSelected
//                   ? <ButtonAdded onClick={handleAddToCart} />
//                   : <ButtonAdd onClick={handleAddToCart} />
//               ) : (
//                 <ButtonAdd multiple />
//               )}
//             </div>
//           ) : (
//             <div className="product-action inline-flex items-center">
//               <span className="text-sm text-red-600">Sin stock</span>
//             </div>
//           )}

//           <div className="flex flex-row flex-wrap justify-between">
//             {/* aquiiiiiii */}
//             <Price />

//             {(prodColors.length > 0) && (
//               <div className="color-preview">
//                 {prodColors.map((i) => {
//                   const hexColor = colors.find(item => item.id == i)?.hex || '#FFFFFF';
//                   return (
//                     <div
//                       key={`color-${product.id}${i}${new Date().getMilliseconds()}`}
//                       className="color-circle"
//                       style={{ backgroundColor: hexColor, borderWidth: 0 }}
//                     />
//                   )
//                 })}
//               </div>
//             )}
//           </div>

//           <div className="product-name">
//             <span onClick={() => !!showProduct ? showProduct() : {}}>
//               {product.name}
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

export default Product;