import React from 'react';

export const ButtonAdd = ({ onClick = () => {}, multiple }) => (
  <button onClick={onClick} className="button-add">
    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12H18M12 6V18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    <span className="text-xs md:text-sm font-semibold">{ multiple ? 'Opciones' : 'Añadir' }</span>
  </button>
)

export const ButtonAdded = ({ onClick }) => (
  <button onClick={onClick} className="button-added">
    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12H18M12 6V18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    <span className="text-xs md:text-sm font-semibold">Añadido</span>
  </button>
)